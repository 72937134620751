<template>
  <div class="mb-4">
    <CContainer>
      <CRow class="align-items-end">
        <CCol md="6">
          <section-title
            :tagline="title"
            :title="subtitle"
            :description="description"
          />
        </CCol>
        <CCol md="6">
          <swiper-nav
            leftClassName="testiPprevArrow"
            RightClassName="testiNextArrow"
          />
        </CCol>
      </CRow>
    </CContainer>
  </div>
  <CContainer>
    <CRow>
      <CCol md="12">
        <div class="pt-4">
          <swiper
            :modules="modules"
            :breakpoints="breakpoints"
            :autoHeight="false"
            :slides-per-view="2"
            :space-between="50"
            :navigation="{
              nextEl: '.testiNextArrow',
              prevEl: '.testiPprevArrow',
            }"
            :pagination="{ clickable: true }"
            :scrollbar="{ draggable: true }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide
              v-for="testimonie in testimonials"
              :key="testimonie.id"
            >
              <CCard class="p-4">
                <testimonie :testimonie="testimonie" />
              </CCard>
            </swiper-slide>
          </swiper>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { CRow, CCol, CContainer, CCard } from "@coreui/vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SectionTitle from "@/components/shared-components/section-title/SectionTitle.vue";
import SwiperNav from "@/components/shared-components/swiper-nav/SwiperNav.vue";
import Testimonie from "@/components/shared-components/testimonie/Testimonie.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { ref } from "vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  components: {
    CRow,
    CCol,
    CContainer,
    CCard,
    Swiper,
    SwiperSlide,
    SectionTitle,
    SwiperNav,
    Testimonie,
  },
  name: "Testimonials",
  props: ["title", "subtitle", "description"],
  data() {
    return {
      testimonials: ref([]),
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },

        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
      },
    };
  },

  updated() {
    //add alt to images
    window.imageALT();
  },

  mounted() {
    this.getTestimonialDetail();
  },

  methods: {
    async getTestimonialDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-testimonial-list";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let testimonialList = response.data.data;
          if (response.data.status_code === 200 && testimonialList.length > 0) {
            Object.entries(testimonialList).forEach(([key, value]) => {
              this.testimonials.push({
                id: ++key,
                name: value.name,
                text: value.description,
                designation: value.designation,
                image: value.image_path,
              });
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
