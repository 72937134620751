<template>
  <svg
    width="60"
    height="43"
    viewBox="0 0 60 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_250_3607)">
      <path
        d="M4.28571 42.8906H17.1429L25.7143 26.0906V0.890625H0V26.0906H12.8571L4.28571 42.8906ZM38.5714 42.8906H51.4286L60 26.0906V0.890625H34.2857V26.0906H47.1429L38.5714 42.8906Z"
        fill="#EC1B23"
      />
    </g>
    <defs>
      <clipPath id="clip0_250_3607">
        <rect
          width="60"
          height="42"
          fill="white"
          transform="translate(0 0.890625)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "QuoteIcon",
};
</script>

<style lang="scss" scoped></style>
